body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Poppins', sans-serif;
}

.cursor-default {
    cursor: default;
}

.input-ctrl {
    margin-bottom: 1.5rem;
}
.error-block{
  text-align: left;
  font-size: 14px;
  margin-top: 0.2rem;
}
input:focus,select:focus {
  box-shadow: none !important;
  outline: none !important;
  border-color: rgba(35,43,54,.2);
}
button:focus{
  border: none;
  box-shadow: none !important;
  outline: none !important;
}
input.checkbox-input{
  width: 20px !important;
  height: 20px;
  padding: 0  !important;
}
.text-blue {
    color: #243a76;  
}
.hand {
    cursor: pointer;
}
.ReactModal__Overlay {
    z-index: 9;
}

// react-select

.select-icon-hide {
    .search-select__indicators, .search-select-1__indicators {
        display: none;
    }
}

.search-select-1__control {
    border-radius: 0px !important;
}

.search-select__control {
    padding: 0.2rem;
}

.commom-page {
    .app-primary-btn{
        background-color: #243a76;
        color: #fff;
        font-weight: 400;
        border-radius: 6px;
        padding: 0.7rem;
        font-size: 1rem;
        width: 100%;
        border: 1px solid #243a76;
      }
      .app-cancel-btn{
        border: 1px solid #243a76;
        background-color: #fff;
        color: #243a76;
      }
}

// login
.login-page{
  height: 100vh;
  .d-logo{
    width: 180px;
    margin: 0 auto 1.5rem;
    img{
      width: 100%;
      height: 100;
    }
  }
  .wel-txt{
    font-size: 18px;
    font-weight: 400;
  }
  label{
    color: #232b36b3;
    font-size: 14px;
    a{font-size: 13px;}
  }
  input,select{
    border-radius: 6px;
    background-color: #fff;
    width: 100%;
    border: 1px solid rgba(35,43,54,.2);
    padding: 0.7rem;
   
  }
  select{
    background-image: url("./icons/chevron-down-solid.svg");
    background-repeat: no-repeat;
    background-position: 100% 52%;
    background-size: 15% 35%;
  }
  .login-btn{
    background-color: #243a76;
    color: #fff;
    font-weight: 400;
    border-radius: 6px;
    padding: 0.7rem;
    font-size: 1rem;
    width: 100%;
    border: 1px solid #243a76;

  }
  .cancel-btn{
    border: 1px solid #243a76;
    background-color: #fff;
    color: #243a76;
  }
  .small-btn {
    padding: 6px;
   }
}
.heading{
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  .backbutton{
    background-color: transparent;
    width: 20px;
    height: 20px;
    left: 0;
    right: 0;
  }
}
// .page-body{
//     // max-height: calc(100vh - 190px);
//     // overflow-y: scroll;
//     // overflow-x: hidden;
// }
// category
.category-list{
  .nav-tabs{
    width: 80%;
    margin: auto;
    .nav-item{
      width: 50%;
      .nav-tab {
        // background-color: #ccc;
        border-radius: 50px;
        text-align: center;
        padding: 0.3rem;
        font-size: 14px;
        color: #000;
      }
      .nav-link {
        text-align: center;
        padding: 0.3rem;
        font-size: 14px;
        color: #000;
      }
      .active{
        background-color: #ccc;
        border-radius: 50px;
      }
    }
  }
  .card{
    .card-body{
      p{
        font-size: 16px;
      font-weight: 400;
      }
    }
  }
}

// transaction
.transation-list{
  .card{
    border-radius: 6px;
    .card-body{
      display: grid;
      grid-template-columns: 158px 158px;
      div.view-btn{
        right: 11px;
        top: 0;
        font-size: 14px;
        cursor: pointer;
      }
      .card-items{
        label{
          color: #232b36b3;
        font-size: 13px;
        text-transform: capitalize;
        }
        p{
          font-size: 15px;
          font-weight: 500;
        }
      }
    }
  }
}

.ScrollToTop {
    background-color: #243a7600;
    right: 40px;
    bottom: 50px;
    position: fixed;
    z-index: 2;
    cursor: pointer;
    border-radius: 7px;
    width: 40px;
    height: 40px;
    transition: opacity 1s ease-in-out;
    border: none;
    outline: none;
    i {
        font-size: 2rem;
        color: #243a76;
    }
}

.date-time-range-picker {
    &.hidecalendar {
        .calendarGrid {
            display: none !important;
        }
        .monthYearContainer{
            display: none !important;
        }
        .fromDateHourContainer {
            display: none !important;
        }
        .activeNotifier {
            display: none !important;
        }
    }
    &.hideArrowIcon {
        #daterangepicker::before, #daterangepicker::after {
            display: none;
        }
    }
    &.hideFromDateTime {
        .fromDateTimeContainer:nth-child(even) {
           display: none;
        }
    }
    &.hideToDateTime {
        .fromDateTimeContainer:nth-child(odd) {
            display: none;
        }
    }
    &.hideRange {
        .fromDateTimeContainer {
            width: 270px !important;
        }
        .rangecontainer {
            display: none !important;
        }
    }
    &.hideInputGroup {
        .input-group {
            display: none;
        }
    }
    &.hideButton {
        .buttonContainer {
            display: none;
        }
    }
    &.hideTimeSelect {
        .timeSelectContainer {
            display: none;
        }
    }
    #DateRangePickerContainer{
        .daterangepicker {
            z-index: 5;
        }
        .dateTimeLabel {
            padding: 0;
        }
        .multipleContentOnLine select {
            height: 40px;
            padding: 0 20px;
        }
        .fromDateTimeContainer {
            width: auto;
        }
        .rangecontainer {
            #rangeButton0 {
                margin-top: 3rem !important;
            }
        }
        #buttonContainer {
            top: 0  !important;
            bottom: unset !important;
        }
    }
}

.pdf-div{
  padding: 50px;
  background-color: #dedede;
  margin-top: 50px;
}
canvas.react-pdf__Page__canvas {
  margin-bottom: 50px;
}

