.PrivateLayout {
    background-color: #fff;
}

.PublicLayout {
    background-color: #fff;
}

// navbar
.offcanvas-start{
    z-index: 9999;
    border-right: none;
    width: 70%;
    .offcanvas-header{
      box-shadow: 0 0px 7px 0px #ccc;
    }
  }
  nav{
      box-shadow: 0 0px 7px 0px #ccc;
      position: sticky;
      top: 0;
      background: #fff;
      z-index: 1;
    button{
      i{
        font-size: 32px;
        color: #243a76;
      }
    }
    ul{
      height: calc(100vh - 150px);
      li{
        div{
          cursor: pointer;
          text-decoration: none;
          font-size: 16px;
          color: #000;
          font-weight: 600;
          text-transform: capitalize;
          border: 1px;
            border-color: #cccc;
            border-style: solid;
            border-right: none;
            border-left: none;
            padding: 16px 0;
            border-top: none;
        }
      }
    }
    .version-box {
        right: 0;
        left: 0;
        bottom: 75px;
    }
    .sidebar-img-main{
      // width: 100%;
      right: 0;
      left: 0;
      bottom: 0;
      box-shadow: 0 0px 7px 0px #ccc;
      padding: 1rem 0;
      .sidebar-logo{
        width: 100px;
        img{
            max-width: 100px;
            max-height: 40px;
        }
      }
    }
  }

.environment {
    position: fixed;
    bottom: 0;
    right: 20px;
    padding: 10px;
    background-color: #243a77;
    border-radius: 8px 8px 0 0;
    color: #fff;
    font-size: 12px;
    box-shadow: 0 0px 7px 3px #243a7759;
  }

  .multi-language {
    position: fixed;
    bottom: 0;
    left: 20px;
    padding: 10px;
    background-color: #243a77;
    border-radius: 8px 8px 0 0;
    color: #fff;
    font-size: 12px;
    box-shadow: 0 0px 7px 3px #243a7759;
  }

