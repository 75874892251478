.form-control-class{
    display: inline-block;
    width: fit-content;
  }
  .print-only {
    display: none; /* Hide by default */
  }
  
  @media print {
    .print-only {
      display: block; /* Show only when printing */
      /* Add any additional styles specific for printing */
    }
  }
  .print-hide {
    display: block; /* Hide by default */
  }
  
  @media print {
    .print-hide {
      display: none; /* Show only when printing */
      /* Add any additional styles specific for printing */
    }
  }
  
  